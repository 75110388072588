import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import WorkGrid from "../modules/WorkGrid/index";
import Blurb from "../components/Blurb/index";
import ImageOverlap from "../components/ImageOverlap/index";
import ColCon from "../components/ColCon/index";
import Content from "../components/Content/index";
import WorkIntro from "../components/WorkInro/index";
import { graphql } from 'gatsby';

class WorkDetail extends Component {
    render() {

        const post = this.props.data.wordpressWpCaseStudy;
        let imageCount = 0;
        const acfFields = this.props.data.wordpressWpCaseStudy.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = post.title;
        }

        let path = this.props.data.wordpressWpCaseStudy.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <WorkIntro
                    title={post.title}
                    videoID={post.acf.vimeo_video_id}
                    blurb={post.acf.blurb}
                    brandLogo={post.acf.brand_logo}
                />
                <ImageOverlap
                    side={post.acf.project_photos.align_images}
                    portrait={post.acf.project_photos.portrait_image}
                    landscape={post.acf.project_photos.landscape_image}
                />
                {post.acf.columns[2].column_content !== '' ?
                    <ColCon
                        columns={post.acf.columns}
                    />
                : null }
                {post.acf.block_content !== '' ?
                    <Content
                        content={post.acf.block_content}
                    />
                : null }
                {post.acf.outcome_blurb !== '' ?
                <Blurb
                    content={post.acf.outcome_blurb}
                />
                : null }
                {post.acf.additional_images && post.acf.additional_images.map(({stacked_images}) => {
                    imageCount++
                    return <ImageOverlap
                        key={"image-overlap-"+imageCount}
                        side={stacked_images.align_images}
                        portrait={stacked_images.portrait_image}
                        landscape={stacked_images.landscape_image}
                    />
                })}
                <WorkGrid/>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpCaseStudy(id: { eq: $id }) {
      id
      slug
      title
      acf {
        vimeo_video_id
        blurb
        brand_logo {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 190, maxHeight:100, quality: 90, cropFocus: CENTER) {
                      ... GatsbyImageSharpFluid
                    }
                }
            }
        }
        project_photos {
          align_images
          portrait_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 440, maxHeight:560, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          landscape_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 790, maxHeight:490, quality: 90, cropFocus: CENTER) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        columns {
          column_heading
          column_content
        }
        block_content
        outcome_blurb
        additional_images {
          stacked_images {
            align_images
            portrait_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 440, maxHeight:560, quality: 90, cropFocus: CENTER) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
            landscape_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 790, maxHeight:490, quality: 90, cropFocus: CENTER) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
      }
    }
  }
`

export default WorkDetail
