import React from 'react'
import './style.less';
import {Row, Col} from "antd";
import WorkListItem from "../../components/WorkListItem";
import Link from "../../components/Link";
import {graphql, StaticQuery} from "gatsby";

class WorkGrid extends React.Component {

    render(){

        return (
            <div className="work-grid">
                <Row>
                    <Col xs={{span: 24}}>
                        <h2 className="huge">More</h2>
                    </Col>
                </Row>
                <Row type="flex">
                    {this.props.work && this.props.work.map(({node}) => {
                        return (
                            <Col xs={{span: 24}} md={{span:12}} lg={{span: 8}} key={node.id}>
                                <WorkListItem
                                    work={node}
                                />
                            </Col>
                        )
                    })}
                    <Col xs={{span: 24}}>
                        <p className="text-center">
                            <Link to="/work/" className="arrow-link">All work</Link>
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql` query moreWorkListQuery {
            allWordpressWpCaseStudy(limit: 3, sort: { order: DESC, fields: [date] }){
              edges {
                node {
                  wordpress_id
                  id
                  date
                  slug
                  title
                  acf {
                    featured_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                            ... GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    listing_blurb
                  }
                }
              }
            }
           }
        `}
        render={(data) => (
            <WorkGrid work={data.allWordpressWpCaseStudy.edges} />
        )}
    />
)