import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import ReactHtmlParser from "react-html-parser";

class ColCon extends React.Component {
  render() {
    return (
      <Row className="column-content">
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <h3>{ReactHtmlParser(this.props.columns[0].column_heading)}</h3>
          <p>{ReactHtmlParser(this.props.columns[0].column_content)}</p>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <h3>{ReactHtmlParser(this.props.columns[1].column_heading)}</h3>
          <p>{ReactHtmlParser(this.props.columns[1].column_content)}</p>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <h3>{ReactHtmlParser(this.props.columns[2].column_heading)}</h3>
          <p>{ReactHtmlParser(this.props.columns[2].column_content)}</p>
        </Col>
      </Row>
    );
  }
}

export default ColCon;
