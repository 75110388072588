import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import {Link} from "@reach/router";
import Img from "gatsby-image";
import ReactHtmlParser from 'react-html-parser';

class WorkIntro extends React.Component {

    render(){

        return (
            <div className="work-intro">
                <Row>
                    <Col className="text-center">
                        <Link className="back-link" to="/work/">All Work</Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}}>
                        <Img fluid={this.props.brandLogo.localFile.childImageSharp.fluid} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} md={{span:8}}>
                        <h2>{ReactHtmlParser(this.props.title)}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 20, offset: 2}} md={{span:16, offset: 8}}>
                        <div className="responsive-video">
                            <iframe title="work-intro-video" src={"https://player.vimeo.com/video/"+ this.props.videoID+"?title=0&byline=0&portrait=0"} frameBorder="0"></iframe>
                        </div>
                    </Col>
                    <Col xs={{span: 24}}>
                        <div className="blurb" dangerouslySetInnerHTML={{__html: this.props.blurb }} >

                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default WorkIntro